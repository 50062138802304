import {type FC} from 'react';
import {cva, type VariantProps} from "class-variance-authority";

const indicatorStyles = cva(
  '-ml-1 mr-3 animate-spin',
  {
    variants: {
      size: {
        md: 'size-5',
        lg: 'size-8',
        xl: 'size-10'
      },
      color: {
        white: "text-white",
        brand: "text-brand",
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

interface Props extends VariantProps<typeof indicatorStyles> {
  className?: string;
}

export const LoadingIndicator: FC<Props> = function ({className = '', ...props}) {
  return <svg className={indicatorStyles({className, ...props})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>;
}
